import React from 'react';
import './Header.css';

function Header({title, imgSrc,token,IsAdmin,useremail}) {
     return  <header id="app-header" class="_header_3rfbo_1">
  <div class="_logoContainer_3rfbo_14">
      <img class ="header_logo" src={imgSrc} width="100" height="26"/>
      <div class="header_innovationLab"> Innovation Lab  </div>
  </div>
  <div class="top_navigation">
    <div class="top_frame">
    <div class="top_navigation">
            <a class="menu_item" href="#" onClick={() => { requestForm(token) }} >Request</a>
            <a class="menu_item" href="#" onClick={() => { system_information_redirect(token) }}  >System Information {IsAdmin}</a>
            <a class="menu_item" href="https://inno-lab-demo.ideas.aha.io/portal_session/new"   target="_blank">Submit an Idea {IsAdmin}</a>
            <a class="menu_item" href="#" style={IsAdmin ===true ?{visibility:"visible"}:{visibility:"hidden"}} onClick={() => { AdministrationRedirect(token) }} >Template Administration</a>
            
        </div>
        <div class="top_rectangle"></div>
       
          
    </div>
  </div>
  <div class="_rightSideContainer_3rfbo_27">
      <a id="appHeaderPortalHelpLink" href="#" onClick={() => { system_information_redirect(token) }} class="_helpLink_3rfbo_37" rel="noopener noreferrer" aria-label="Innovation lab help"><span class="icon-Resource-Center---Help" aria-hidden="true"></span></a>
      <div id="appHeaderProfilePageLink" class="_container_1ypzn_1">
        <div class="_link_1ypzn_4" aria-label="Profile" >{getUserLoginName(useremail)}</div>
      </div>
  </div>
</header>       
  }

Header.defaultProps = {
    title: 'Verint',
}
function requestForm(token) {
    var url = window.location.href;
    var token = url.split('#')[1];
     window.location.href = "/form#" + token + "";
}

function AdministrationRedirect(token) {
    var url = window.location.href;
    var token = url.split('#')[1];

    window.location.href =  "/template-admin#" + token + "";
}

function system_information_redirect(token) {
    var url = window.location.href;
    var token = url.split('#')[1];

    window.location.href =  "/system_information#" + token + "";
}
function getUserLoginName(useremail) {
    let name = useremail 
    if (name != null)
    {
        try{
        name= name.split("@")[0].replace("."," ");
        // Create an array containing each of the words in the name
        var names = name.split(/\s+/);
        
        // Replaces the first name with an initial, followed by a period.
        names[0] = names[0].substr(0, 1) 
        names[1] = names[1].substr(0, 1) 
        console.log(names[1])
        // Glue the pieces back together.
        var name_abbr = names.join('').toUpperCase();
        
        console.log(name_abbr);
        }catch(e)
        {
            console.log(e.message)
        }
    }
    return name_abbr;
}

export default Header;